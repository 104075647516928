@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root[data-theme="theme-light"] {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #7189ff;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  
  --glass-bg-color: rgba(255,255,255, 0.25);
  --glass-border-color: rgba(255,255,255, 0.18);
}

:root[data-theme="theme-dark"] {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #121212;
  --secondary-color: #7189ff;
  --black-color: #ffffff;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #030303;

  --glass-bg-color: rgba(0,0,0, 0.25);
  --glass-border-color: rgba(0,0,0, 0.18);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
