.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 270px;
    flex-direction: column;


    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--white-color);
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }

  .expanded {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-Index: 999;
    width: 80%;
    height: 90%;
    display: grid;
    place-items: center;
    background: var(--primary-color);
    padding: 0;
    cursor: default;

    h1 {
      font-size: 27px;
      color: var(--black-color);
      padding: 5px;
      place-self: center;
    }

    p {
      padding: 5px;
      place-self: center;
      color: var(--black-color);
    }

    .blog-bg {
      width: 100%;
      background: var(--white-color);
      display: grid;
      padding: 2rem;
      width: 98%;
      border-radius: 0.4rem;
    }

    .closeWork {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0.8rem;
      right: 0.8rem;
      opacity: 0.3;
      z-index: 999;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: var(--gray-color);
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    .yes-scroll {
      overflow-y: scroll;
      height: 100%;
      width: 100%;
    }

    .image-container {
      position: relative;
      width: 80%;
      height: 400px;
      cursor: zoom-in;
      margin: 50px 0;
      margin: auto;
    }

    .image-container.open {
      cursor: zoom-out;
    }

    .image-container .shade {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      opacity: 0;
      background: rgba(0, 0, 0, 0.9);
    }

    .image-container.open .shade {
      pointer-events: auto;
      opacity: 1;
      z-index: 1000;
    }

    .image-container img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      margin: auto;
      object-fit: scale-down;
    }

    .image-container.open img {
      position: fixed;
      width: auto;
      height: 100%;
      max-width: 100%;
      margin: auto;
      z-index: 1000;
    }

    @media screen and(max-width: 1200px) {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;

      .closeWork {
        position: fixed;
        font-size: 3rem;
        z-index: 2;
      }
    }

    @media screen and(min-width: 1250px) {
      width: 1200px;
      height: 90%;
      margin: auto;
      top: 0;
      left: 0;
    }
  }

  .app__modal_blur {
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-Index: 999;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  position: relative;



  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }


  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: #fff;
    }
  }

  @media screen and (max-width:1200px) {
    a {
      display: none;
    }
  }

}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;



  h4 {
    margin-top: 1rem;
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--white-color);
    top: -25px;

    .more-tags {
      position: relative;
    }
  }
}

.no-scroll {
  overflow: hidden;
}