@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&display=swap');

#home {
  position: relative;
  //background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: 100vh;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-profile {

    font-family: 'Raleway', sans-serif !important;
    font-weight: 900;
    line-height: 6.5vw;
    font-size: 8vw;
    text-transform: uppercase;
    float: left;
    flex-direction: row;

    .animateText {
      position: relative;
      display: flex;
      overflow: hidden;

      p {
        font-size: 8vw;
      }

      &:nth-child(1) p {
        color: var(--secondary-color);
        font-size: 5vw;
      }

      &:nth-child(3) p {
        font-size: 5vw;
      }

      @media screen and (min-width: 1200px) {
        top: 20vh;

      }

    }

    p {
      color: var(--black-color);
    }

    @media screen and (max-width: 1200px) {
      font-size: 50px;
      margin: auto;
      line-height: 6vh;

      .animateText {
        font-size: 100px;

        p {
          font-size: 15vw;
        }

        &:nth-child(1) p {
          color: var(--secondary-color);
          font-size: 13vw;
        }

        &:nth-child(3) p {
          font-size: 9vw;
        }
      }

      p {
        font-size: 100vw;
      }
    }

    @media screen and (max-width:1200px) {
      vertical-align: middle;
      height: 50%;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;
  width: auto;
  line-height: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;


  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .profile-img {
    width: 640px;
    height: 760px;
    max-width: 100%;

    .overlay {
      position: absolute;
      margin: auto;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    height: 760px;

    div {
      margin: 1rem;
    }
  }
}